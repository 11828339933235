.room-popover {
  position: absolute;
  width: 310px;
  padding: 30px 25px;
  display: none;
  box-shadow: 0 0 36px rgba($color-black, 0.2);
  background-color: $color-concrete;
  &__slider {

  }
  .photo {

    height: 180px;
    width: 280px;
    position: relative;
    overflow: hidden;
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
      display: block;
    }
  }
  &__content {
    color: $color-dove-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 31px;
    padding-top: 24px
  }

  &__close {
    border: 0 solid;
    text-indent: -10000px;
    height: 15px;
    width: 15px;
    position: absolute;
    outline: 0;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../static/images/icons/close.png");
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .tns-outer {
    position: relative;
    .tns-controls {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -20px;
      button {
        border: 0 solid;
        background: none;
        text-indent: -10000px;
        height: 40px;
        padding:0;
        margin:0;
        width: 15px;
        position: absolute;
        outline: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &:first-child {
          left: -20px;
          background-image: url("../static/images/icons/left.png");
        }
        &:last-child {
          right: -20px;
          background-image: url("../static/images/icons/right.png");
        }
      }
    }
  }
}
