body {
  color: $color-dove-gray;
  font-family: $font-open-sans;
  font-size: 14px;
  font-weight: 400;

  .clear {
    clear: both;
  }

  .container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 15px;
  }

  .text-center {
    text-align: center;
  }

  .buttons {
    text-align: center;
    margin: 20px 0;
  }

  .btn {
    background-color: $color-tussock;
    display: inline-block;
    padding: 11px 10px;
    color: $color-white;
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: none;
    border: 0 solid;
    @include respond-to('desktop') {
      padding: 16px 20px;
      font-size: 14px;
    }

    &--block {
      display: block;
    }

    &--icon-mail {
      padding-right: 30px;
      position: relative;

      &:after {
        content: "";
        background-image: url("../static/images/icons/envelope.png");
        background-repeat: no-repeat;
        display: block;
        width: 14px;
        height: 10px;
        position: absolute;;
        right: 10px;
        top: 50%;
        margin-top: -5px;
      }
    }
  }

  .mobile-header {
    color: $color-dove-gray;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 0;
    text-align: center;
  }
}


.page-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
}