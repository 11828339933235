.room-gallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-white, .5);
  display: none;
  z-index: 100;
  &__backdrop {
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
  }
  &__inner {
    height: 640px;
    width: 100%;
    max-width: 1150px;
    max-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 13px 12px rgba($color-black, 0.05);
    background-color: $color-white;
  }
  &__close {
    border: 0 solid;
    text-indent: -10000px;
    height: 20px;
    width: 20px;
    position: absolute;
    outline: 0;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../static/images/icons/close.png");
    top: 15px;
    right: 15px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  &__container {
    position: absolute;
    top: 40px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    @include respond-to('desktop') {
      top: 40px;
      left: 60px;
      right: 60px;
      bottom: 40px;
    }

  }
  &__slider {
    .photo {
      height: 560px;
      max-height: calc(100vh - 40px);
      position: relative;
      overflow: hidden;
      img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include respond-to('desktop') {
          height: 100%;
          width: auto;
          position: static;
          transform: none;
        }
      }
    }
  }

  .tns-outer {
    position: relative;
    .tns-controls {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -30px;
      z-index: 1;
      button {
        border: 0 solid;
        background: none;
        text-indent: -10000px;
        height: 60px;
        padding:0;
        margin:0;
        width: 30px;
        position: absolute;
        outline: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &:first-child {
          left: 5px;
          background-image: url("../static/images/icons/left.png");
          @include respond-to('desktop') {
            left: -30px;
          }
        }
        &:last-child {
          right: 5px;
          background-image: url("../static/images/icons/right.png");
          @include respond-to('desktop') {
            right: -30px;
          }
        }
      }
    }
  }
}
