.room-details {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-white, .5);
  display: none;
  &__backdrop {
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
  }
  &__inner {
    height: 640px;
    width: 100%;
    max-width: 1150px;
    max-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 13px 12px rgba($color-black, 0.05);
    background-color: $color-white;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    @include respond-to('desktop') {
      padding: 45px 55px;
    }
  }
  &__close {
    border: 0 solid;
    text-indent: -10000px;
    height: 20px;
    width: 20px;
    position: absolute;
    outline: 0;
    background: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../static/images/icons/close.png");
    top: 2px;
    right: 2px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    @include respond-to('desktop') {
      top: 15px;
      right: 15px;
    }
  }
}
.room-details__content,
.room-details__image {
  width: calc(50% - 30px);
}
.room-details-title {
  line-height: 1.1;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  @include respond-to('desktop') {
    margin-bottom: 30px;
    font-size: 30px;
  }
}
.room-details-title span {
  text-transform: uppercase;
}
.room-details-list {
  column-count: 1;
  column-gap: 20px;
  margin-bottom: 20px;
  @include respond-to('desktop') {
    column-count: 2;
  }
}
.room-details-list li {
  break-inside: avoid;
  list-style: disc;
  margin-left: 18px;
}
.room-details-icons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.room-details-icons img {
  margin-right: 8px;
}
.room-details-table {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1px;
}
.room-detail {
  width: calc(100% / 5 + 1px);
  margin-top: 10px;
  margin-left: -1px;
}
.room-detail-icon {
  height: 50px;
  background: center no-repeat;
}
.room-detail-label {
  background: $color-concrete;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.room-detail-value {
  border: solid 1px $color-concrete;
  padding: 10px;
  text-align: center;
}

.room-details__image {
  margin-top: 62px;
}
.room-details-figure {
  position: relative;
  margin-bottom: 20px;
  @include respond-to('desktop') {
    margin-bottom: 0px;
  }
}
.room-details-figure::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  background: url(../static/images/icons/camera.png) center no-repeat $color-tussock;
  width: 31px;
  height: 31px;
}
.room-details-img {
  width: 100%;
  max-height: 240px;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
  @include respond-to('desktop') {
    max-height: 350px;
  }
}
.room-details-btn-mail {
  margin-top: 50px;
}
.room-details-magnific {
  cursor: pointer;
  background: rgba(#ffffff, 0.9);
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.room-details-magnific.active {
  opacity: 1;
  visibility: visible;
}
.room-details-magnific img {
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  cursor: pointer;
  box-shadow: 0 0 20px 0 rgba(#000000, 0.3);
  @include respond-to('desktop') {
    max-width: calc(100vw - 50px);
    max-height: calc(100vh - 50px);
  }
}