$font-arial-black: "Arial Black";
$font-arial: "Arial";
$font-open-sans: "Open Sans";

$color-white: #ffffff;
$color-dove-gray: #626262;
$color-tussock: #c08040;
$color-woodsmoke: #181819;
$color-silver-chalice: #a0a0a0;
$color-concrete: #f2f2f2;
$color-black: #000000;

$breakpoints: (
        'mobile-only' : (max-width: 1199px),
        'desktop':  ( min-width: 1200px )
) !default;
