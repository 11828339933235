.table {
  width: 100%;
  position: relative;

  &--header {

  }

  &--room {

  }

  &__cell {
    width: 33.3333%;
    float: left;
    display: inline-block;

    margin-bottom: 20px;

    @include respond-to('desktop') {
      width: (100%/9);
      margin-bottom: 3px;
    }


    &__header {
      padding-top: 32px;
      background-repeat: no-repeat;
      background-position: top center;
      &--teatralne {
        background-image: url("../static/images/icons/teatralne.png");
      }

      &--koktajlowe {
        background-image: url("../static/images/icons/koktajlowe.png");
      }

      &--bankiet {
        background-image: url("../static/images/icons/bankiet.png");
      }

      &--klasowe {
        background-image: url("../static/images/icons/klasowe.png");
      }

      &--litera_u {
        background-image: url("../static/images/icons/litera_u.png");
      }

      &--owalny_stol {
        background-image: url("../static/images/icons/owalny_stol.png");
      }

      &--board {
        background-image: url("../static/images/icons/board.png");
      }

      &--kabaret {
        background-image: url("../static/images/icons/kabaret.png");
      }

      &--swiatlo {
        background-image: url("../static/images/icons/swiatlo.png");
      }

      span {
        display: block;
        text-align: center;
        background-color: $color-concrete;
        line-height: 1.1;
        padding: 8px 2px;
        line-height: 21px;
      }
    }

    &__value {
      text-align: center;
      padding: 10px 5px;
      border: 1px solid $color-concrete;
    }

    @include respond-to('desktop') {
      .table__cell__value {
        border-right: 0 solid;
      }
      &:last-child {
        .table__cell__value {
          border-right: 1px solid $color-concrete;
        }
      }
    }

    @include respond-to('mobile-only') {
      &:nth-child(3n-1) {
        .table__cell__value {
          border-right: 0 solid;
          border-left: 0 solid;
        }
      }
    }


  }
}


.roomtbl {

  clear: both;
  width: 100%;
  position: relative;

  &__header {
    @include respond-to('desktop') {
      width: 270px;
      float:left;
    }
  }
  &__images {
    display: block;
    float: left;
    width: 50px;
    a {
      display: block;
      width: 30px;
      height: 30px;
      color: #f2f2f2;
      background: url("../static/images/icons/camera.svg") center center / 24px no-repeat transparent;
      margin: 6px 10px 0;
    }
  }
  &__name {

    width: 200px;
    float: right;
    padding: 10px;
    background-color: $color-concrete;
    display: block;
    border: 1px solid $color-concrete;
    margin-bottom: 3px;
    @include respond-to('desktop') {
      width: 220px;
    }
    &--header {
      margin-top: 32px;
      line-height: 21px;
      max-height: 58px;
      padding: 8px;
      height: 58px;
    }
    &--select {
      background-color: transparent;
      padding: 0;
      border: none;
      select {
        width: 100%;
        border: 1px solid $color-concrete;
        background-color: $color-white;
        padding: 10px;
      }
    }
  }
  &__content {
    @include respond-to('desktop') {
      width: 870px;
      float: left;
    }
  }
  &__stage {
    clear: both;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    padding: 15px 0 25px;
    &.desktop {
      padding: 10px 0 10px;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
    }
  }
}


.rooms {
  @include respond-to('mobile-only') {
    margin-top: 20px;
    border: 1px solid $color-concrete;
    padding: 10px;
  }
  .buttons {
    .btn {
      padding-left: 20px;
      padding-right: 20px;
      &--icon-mail {
        padding-right: 50px;
        &:after {
          right: 25px;
        }
      }
    }
  }
}
