.spatial-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-white, .5);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: 0.3s ease;
}
.spatial-view.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.spatial-view-backdrop {
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
}
.spatial-view-inner {
  height: 640px;
  width: 100%;
  max-width: 1150px;
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 13px 12px rgba($color-black, 0.05);
  background-color: $color-white;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  @include respond-to('desktop') {
    padding: 45px 55px;
  }
}
.spatial-view-close {
  border: 0 solid;
  text-indent: -10000px;
  height: 20px;
  width: 20px;
  position: absolute;
  outline: 0;
  background: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../static/images/icons/close.png");
  top: 2px;
  right: 2px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  @include respond-to('desktop') {
    top: 15px;
    right: 15px;
  }
}
.spatial-view-nav {
  width: 100px;
  position: relative;
  z-index: 1;
}
.spatial-view-nav-link {
  display: block;
  color: $color-tussock;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 40px;
  opacity: 0.5;
  transition: all 0.3s;
  position: relative;
}
.spatial-view-nav-link:hover,
.spatial-view-nav-link.active {
  opacity: 1;
}
.spatial-view-nav-link::after {
  content: '';
  transition: all 0.3s;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 10px;
  background: url(../static/images/icons/arrow-left.png) no-repeat center / 100%;
  opacity: 0;
  transition: all 0.3s;
}
.spatial-view-nav-link.active::after {
  opacity: 1;
}
.spatial-view-tabs {
  width: calc(100% - 100px);
  height: 100%;
}
.spatial-view-tab {
  height: 100%;
  display: none;
  justify-content: space-between;
}
.spatial-view-tab.active {
  display: flex;
}

.spatial-view-area {
  //width: 100%;
  //padding-bottom: calc(841.9 / 1221.3 * 100%); 
  position: relative;
  z-index: 0;
}
.spatial-view-level-0 {
  width: 1040px;
  height: 750px;
  margin: -100px -160px;
  margin-top: -90px;
  margin-bottom: -110px;
}
.spatial-view-level-1 {
  width: 1040px;
  height: 717px;
  margin: -100px -160px;
  margin-top: -110px;
}
.spatial-view-area svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.spatial-view-room-label {
  color: $color-tussock;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  border: 1px solid $color-tussock;
  width: 100px;
  padding: 6px;
  line-height: 1.1;
  background: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  letter-spacing: -0.3px;
  pointer-events: none;
}
.spatial-view-label {
  color: $color-tussock;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  line-height: 1.1;
  position: absolute;
  transform: translate(-50%, -50%);
  letter-spacing: -0.3px;
  max-width: 100px;
  padding: 6px;
  pointer-events: none;
}
.map-arrow {
  position: absolute;
  transform: translate(-50%, -50%);
  display: block;
  pointer-events: none;
}
.map-arrow-left {
  @extend .map-arrow;
  background: url(../static/images/icons/map-arrow-left.png) center / 100% no-repeat;
  width: 9px;
  height: 10px;
}
.map-arrow-right {
  @extend .map-arrow;
  background: url(../static/images/icons/map-arrow-right.png) center / 100% no-repeat;
  width: 9px;
  height: 11px;
}
.map-arrow-up {
  @extend .map-arrow;
  background: url(../static/images/icons/map-arrow-up.png) center / 100% no-repeat;
  width: 11px;
  height: 9px;
}
.map-arrow-down {
  @extend .map-arrow;
  background: url(../static/images/icons/map-arrow-down.png) center / 100% no-repeat;
  width: 12px;
  height: 8px;
}
.spatial-view-link {
  transition: all 0.3s;
}
.spatial-view-link.active,
.spatial-view-link:hover {
  transform: translateY(-16px);
}
.spatial-view-slider-container {
  width: 266px;
  position: relative;
  z-index: 1;
  padding-top: 34px;
}
.spatial-view-slider {
  height: 480px;
  overflow: hidden;
}
.spatial-view-slider-inner {
  transition: all 0.5s;
}
.spatial-view-slider-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 76px;  
  height: 43px;
  border: 0;
  z-index: 10;
  cursor: pointer;
  padding: 0;
}
.spatial-view-slider-prev {
  @extend .spatial-view-slider-arrow;
  top: -20px;
  background: url(../static/images/icons/scroll-up.png) center / 100% no-repeat;
}
.spatial-view-slider-next {
  @extend .spatial-view-slider-arrow;
  bottom: -20px;
  background: url(../static/images/icons/scroll-down.png) center / 100% no-repeat;
}
.spatial-view-slide {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.spatial-view-slide-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1;
  text-transform: uppercase;
  color: #6a6a6a;
  margin-bottom: 8px;
}
.spatial-view-slide-photo {
  display: block;
  position: relative;
}
.spatial-view-slide-img {
  display: block;
  width: 100%;
  height: 177px;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
}
.spatial-view-slide-photo::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  background: url(../static/images/icons/camera.png) center no-repeat $color-tussock;
  width: 31px;
  height: 31px;
}