.mobile {
  display: block !important;
  @include respond-to('desktop') {
    display: none !important;
  }
}

.desktop {
  display: none !important;
  @include respond-to('desktop') {
    display: block !important;
  }
}

.desktop-inline {
  display: none !important;
  @include respond-to('desktop') {
    display: inline !important;
  }
}

.desktop-inline-block {
  display: none !important;
  @include respond-to('desktop') {
    display: inline-block !important;
  }
}

